var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "transfer-order-modal",
      attrs: {
        title: `Transfer / Revoke Order`,
        color: "dark",
        size: "lg",
        show: _vm.active,
        closeOnBackdrop: false,
      },
      on: {
        "update:show": function ($event) {
          _vm.active = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("CInputCheckbox", {
                staticClass: "mr-auto",
                attrs: {
                  custom: "",
                  checked: _vm.includeNonWorking,
                  label: "Include the couriers that are not working now",
                },
                on: {
                  "update:checked": function ($event) {
                    _vm.includeNonWorking = $event
                  },
                },
              }),
              _vm.courierId != null || _vm.dispatcherId != null
                ? _c(
                    "CButton",
                    {
                      attrs: {
                        color: "danger",
                        disabled:
                          (_vm.courierId == null && _vm.dispatcherId == null) ||
                          _vm.selectedCourier != null ||
                          _vm.submitted,
                      },
                      on: { click: _vm.revokeOrder },
                    },
                    [
                      _vm.courierId != null &&
                      _vm.selectedCourier == null &&
                      _vm.submitted
                        ? _c("CSpinner", {
                            staticClass: "mr-2",
                            attrs: { size: "sm" },
                          })
                        : _vm._e(),
                      _c("CIcon", {
                        staticClass: "mt-0",
                        attrs: { name: "cil-action-undo" },
                      }),
                      _vm._v(" Revoke "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "CButton",
                {
                  attrs: {
                    color: "success",
                    disabled: _vm.selectedCourier == null || _vm.submitted,
                  },
                  on: { click: _vm.transferOrder },
                },
                [
                  _vm.selectedCourier != null && _vm.submitted
                    ? _c("CSpinner", {
                        staticClass: "mr-2",
                        attrs: { size: "sm" },
                      })
                    : _vm._e(),
                  _c("CIcon", {
                    staticClass: "mt-0",
                    attrs: { name: "cil-transfer" },
                  }),
                  _vm._v(" Transfer "),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.loading &&
      _vm.restaurant?.restaurant_postcode &&
      !_vm.couriers.length
        ? _c("div", { staticClass: "alert alert-warning p-2" }, [
            _c("h4", { staticClass: "mb-0" }, [
              _vm._v("⚠️ No couriers are available to service the "),
              _c("strong", [
                _vm._v(_vm._s(_vm.restaurant.restaurant_postcode)),
              ]),
              _vm._v(" postcode area at this time."),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "alert alert-info p-2" }, [
        _c("div", { staticClass: "vertical-center flex-gap-2" }, [
          _c("div", { staticClass: "h1 mb-0" }, [_vm._v("🛈")]),
          _c("div", { staticClass: "flex-grow-1 lead" }, [
            _vm._v(
              " Subordinate couriers are not listed because direct order assignment is not allowed. "
            ),
          ]),
        ]),
      ]),
      _vm.isDeliveryDateToCourierInFuture
        ? _c("div", { staticClass: "alert alert-danger p-2" }, [
            _c("div", { staticClass: "vertical-center flex-gap-2" }, [
              _c("div", { staticClass: "h1 flash-it" }, [_vm._v("⚠")]),
              _c("div", { staticClass: "flex-grow-1 lead" }, [
                _vm._v(
                  " The earliest time the order will be assigned to the courier must be "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      new Date(
                        _vm.order.roAproxDeliveryDateToCourier
                      ).toLocaleString()
                    )
                  ),
                ]),
                _c("br"),
                _vm._v(" You assign the order to the courier about "),
                _c("span", { staticClass: "h5" }, [
                  _vm._v(_vm._s(_vm.timeUntilDeliveryToCourier) + " ago?"),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("CDataTable", {
        staticClass: "table-transfer-order",
        attrs: {
          striped: "",
          hover: "",
          items: _vm.couriers,
          fields: _vm.fields,
          loading: _vm.loading,
          "table-filter": {
            label: "Search:",
            placeholder: "Search for couriers...",
            external: false,
            lazy: false,
          },
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No couriers found!",
          },
          "clickable-rows": "",
        },
        on: { "row-clicked": _vm.rowClicked },
        scopedSlots: _vm._u([
          {
            key: "select",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "no-print" },
                  [
                    !item.disable
                      ? _c("CInputCheckbox", {
                          attrs: { checked: item._selected, custom: "" },
                          on: { "update:checked": () => _vm.check(item) },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "id",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" #" + _vm._s(item?.id) + " ")])]
            },
          },
          {
            key: "image_icon",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c("CImg", {
                      staticClass: "c-avatar-img",
                      staticStyle: { width: "36px", height: "36px" },
                      attrs: {
                        src: item.image_icon || item.gravatar,
                        placeholderColor: "lightgray",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "first_name",
            fn: function ({ item }) {
              return [
                _c("td", [
                  item.suitable
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "c-tooltip",
                              rawName: "v-c-tooltip",
                              value: "The most suitable courier for this order",
                              expression:
                                "'The most suitable courier for this order'",
                            },
                          ],
                          staticClass: "h4",
                        },
                        [_vm._v("⭐")]
                      )
                    : _vm._e(),
                  item.settings?.is_dispatcher
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "c-tooltip",
                              rawName: "v-c-tooltip",
                              value: "Dispatcher",
                              expression: "'Dispatcher'",
                            },
                          ],
                          staticClass: "dot bg-secondary",
                        },
                        [_vm._v("👔")]
                      )
                    : _vm._e(),
                  _vm._v(" " + _vm._s(item.first_name) + " "),
                ]),
              ]
            },
          },
          {
            key: "email",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c(
                    "div",
                    [
                      _c("CIcon", {
                        staticClass: "mr-1",
                        attrs: { name: "cil-at" },
                      }),
                      _vm._v(_vm._s(item.email)),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("CIcon", {
                        staticClass: "mr-1",
                        attrs: { name: "cil-phone" },
                      }),
                      _vm._v(_vm._s(item.mobile)),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "vehicle",
            fn: function ({ item }) {
              return [
                _c("td", [
                  item.vehicle
                    ? _c(
                        "div",
                        { staticClass: "d-flex align-items-center flex-gap-2" },
                        [
                          _c("MIcon", { attrs: { tag: item.vehicle.mode } }),
                          _c("span", { staticClass: "text-uppercase" }, [
                            _vm._v(_vm._s(item.vehicle.license_plate)),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "status",
            fn: function ({ item }) {
              return [
                _c("td", { staticClass: "text-center" }, [
                  _c(
                    "div",
                    { staticClass: "vertical-center flex-gap-1 text-light" },
                    [
                      item.settings?.is_dispatcher
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "c-tooltip",
                                  rawName: "v-c-tooltip",
                                  value: "Dispatcher",
                                  expression: "'Dispatcher'",
                                },
                              ],
                              staticClass: "dot bg-secondary",
                            },
                            [_vm._v("👔")]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          class: {
                            dot: true,
                            "bg-danger": !item.status?.is_working_now,
                            "bg-success": item.status?.is_working_now,
                          },
                        },
                        [_vm._v("W")]
                      ),
                      item.status?.is_working_now && item.status?.on_break
                        ? _c("span", { staticClass: "dot bg-warning" }, [
                            _vm._v("B"),
                          ])
                        : _vm._e(),
                      item.status?.on_order
                        ? _c("span", { staticClass: "dot bg-success" }, [
                            _vm._v("O"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }